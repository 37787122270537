import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Container from 'react-bootstrap/Container';
import { 
  getAuth, 
  signInWithEmailAndPassword, 
  createUserWithEmailAndPassword 
} from 'firebase/auth';
import { createUser, getUser } from "../services/Users";
import { useTranslation } from "react-i18next";

export default function GetStarted() {

  const { t, i18n } = useTranslation();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [emailError, setEmailError] = useState();
  const [passwordError, setPasswordError] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    let authToken = localStorage.getItem('Auth Token');

    if (authToken){
      navigate('/home');
    }
  }, [])

  const clearErrors = () => {
    setEmailError(null);
    setPasswordError(null);
  }

  const missingEmailOrPassword = () => {
    setEmailError(!email ? t("emailRequired.label") : null);
    setPasswordError(!password ? t("passwordRequired.label") : null);
    return !(email && password);
  }

  const register = () => {
    clearErrors();
    if(missingEmailOrPassword()){
      return;
    }
    const authentication = getAuth();
    createUserWithEmailAndPassword(authentication, email, password)
      .then((response) => {
        let userId = response.user.uid;
        localStorage.setItem('Auth Token', response._tokenResponse.refreshToken);
        localStorage.setItem('userId', userId);
        createUser(userId, email);
        navigate('/signup');
      })
      .catch((error) => {
        if (error.code === 'auth/email-already-in-use') {
          setEmailError(t("emailAlreadyInUse.label"));
        }
        else if (error.code === 'auth/weak-password') {
          setPasswordError(t("passwordTooShort.label"));
        }
        else{
          setEmailError(t("unknownError.label"));
          console.log("Error: ", error);
        }
      })
  }

  const login = () => {
    clearErrors();
    if(missingEmailOrPassword()){
      return;
    }
    const authentication = getAuth();
    signInWithEmailAndPassword(authentication, email, password)
      .then(async (response) => {
        let userId = response.user.uid;
        let userInfo = await getUser(userId);
        localStorage.setItem('Auth Token', response._tokenResponse.refreshToken);
        localStorage.setItem('userId', userId);
        localStorage.setItem('userType', userInfo.userType);
        navigate('/home');
      })
      .catch((error) => {
        if(error.code === 'auth/wrong-password'){
          setPasswordError(t("incorrectPassword.label"));
        }
        else if(error.code === 'auth/user-not-found'){;
          setEmailError(t("userNotFound.label"));
        }
        else{
          setEmailError(t("unknownError.label"));
          console.log("Error: ", error);
        }
      })
  }

  const getExercises = async () => {
    // static exercises
    let exercises = ["-2x + 3 = 2", "x - 1 = 0", "4x/2 > 2", "x^2 = 4", "sqrt(x) = 2"];
    exercises = exercises.map(exercise => { return {exercise, completed: false}});
    // navigate to practice page
    let params = {
        exercises: exercises
    };
    navigate('/practice', {state: params});
  }

  return (
    <Container style={{marginTop: 50}}>
      <h1>{t("getStarted.label")}</h1>

      <Button variant="contained" onClick={getExercises}>
        {t("tryItFree.label")}
      </Button>
      <br></br><br></br>
      {t("pleaseRegister.label")}
      <br></br><br></br>
      <Form>
        <TextField 
          error={emailError ? true : false}
          helperText={emailError}
          label={t("email.label")}
          variant="outlined" 
          type="email" 
          placeholder="Enter email" 
          onChange={(e) => setEmail(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          style={{marginBottom:15}}
        />
        <br></br>
        <TextField  
          error={passwordError ? true : false}
          helperText={passwordError}
          label={t("password.label")}
          variant="outlined" 
          type="password" 
          placeholder="Password" 
          onChange={(e) => setPassword(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          style={{marginBottom:15}}
        />
        <br></br>
        <Button variant="contained" onClick={register}>
          {t("register.label")}
        </Button>
        {' '}- {t("or.label")} -{' '}
        <Button variant="contained" onClick={login}>
          {t("login.label")}
        </Button>
      </Form>
    </Container>
  )
}