import React, {useEffect} from "react";
import { Equation, defaultErrorHandler } from "react-equation";
import {getLeftSide, getRightSide} from "../utils";
import { renderToStaticMarkup } from 'react-dom/server'

export default function ShowEquation({equation, comparisonSign, onlyOneSide=false, exerciseIndex, editExercise}) {

  useEffect(() => {
    if(equation !== undefined && exerciseIndex !== undefined && editExercise){
      let renderedLeftside = renderToStaticMarkup(<Equation value={getLeftSide(equation)}/>);
      let renderedRightside = renderToStaticMarkup(<Equation value={getRightSide(equation)}/>);
      
      let isLeftsideError = renderedLeftside.includes("Error:");
      editExercise(exerciseIndex, "leftsideError", isLeftsideError);

      let isRightsideError = renderedRightside.includes("Error:");
      editExercise(exerciseIndex, "rightsideError", isRightsideError);
    }
  }, [equation])
  
  return( 
    <>
      { equation &&
        onlyOneSide ? <Equation value={equation} errorHandler={defaultErrorHandler}/>
        :
        <>
          <Equation value={getLeftSide(equation)} errorHandler={defaultErrorHandler}/>
          {' '}{comparisonSign}{' '}
          <Equation value={getRightSide(equation)} errorHandler={defaultErrorHandler}/>
        </>
      }
      
    </>
  )
} 