import React from "react";
import Container from 'react-bootstrap/Container';
import { useTranslation } from "react-i18next";

export default function About() {

  const { t, i18n } = useTranslation();

  return (
    <Container style={{marginTop: 20}}>
      <h1>{t("pricing.label")}</h1>
      {t("pricing1.label")}
    </Container>
  );
}