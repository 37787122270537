import React from "react";
import { useTranslation } from "react-i18next";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

const LanguageSelector = ({isWhite=false}) => {
  const defaultValue = localStorage.getItem("language") ?? 'en';
  const { t, i18n } = useTranslation();

  const changeLanguage = (event) => {
    let language = event.target.value;
    localStorage.setItem('language', language);
    i18n.changeLanguage(language);
  };

  const languages = [
    {
      name: "English", 
      acronym: "en"
    },
    {
      name: "Español", 
      acronym: "es"
    }
  ]

  return (
    <TextField 
      select 
      label={t("language.label")}
      onChange={changeLanguage} 
      sx={{ 
        minWidth: 130
      }}
      value={defaultValue}
      InputLabelProps={isWhite ? {
        style: { color: "white" },
      } : null}
      InputProps={isWhite ? {
        style: { color: "white" },
        sx: {
          ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
            border: "1px solid white",
          },
          "&:hover": {
            ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
              border: "1px solid white",
            },
          },
        },
      } : null}
    >
      {languages.map((language) => (
        <MenuItem key={language.name} value={language.acronym}>
          {language.name}
        </MenuItem>
      ))}
    </TextField>

  );
};

export default LanguageSelector;