import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

export default function ShowClassrooms({classrooms, setClassrooms}) {
  return( 
    <>
    {
      classrooms.length > 0 ? 
        <Row xs={1} md={2} lg={3} xl={4}>
        {classrooms.map((classroom, index) =>
          <Col key={classroom.id} style={{marginBottom: 20}}>
          <Card style={{ width: '18rem' }}>
            <Card.Body>
              <Card.Title>
                {classroom.classroomName}
              </Card.Title>
              <br></br>
              Code: {classroom.code}
              {' '}
            </Card.Body>
          </Card>
          </Col>
        )}
        </Row>
        : <div>No classrooms yet! Create one.</div>
        }
    </>
  )
};