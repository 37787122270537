import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Container from '@mui/material/Container';
import { getRandomExercises } from "../services/ExercisesService";
import { createNewWorksheet } from "../services/Worksheets";
import { isPositiveInteger } from "../utils";
import { useTranslation } from "react-i18next";

export default function NewWorksheet() {

  const { t, i18n } = useTranslation();
  const [worksheetName, setWorksheetName] = useState();
  const [numberOfExercises, setNumberOfExercises] = useState();
  const [difficulty, setDifficulty] = useState("easy");
  const [nameError, setNameError] = useState();
  const [numExercisesError, setNumExercisesError] = useState();
  const navigate = useNavigate();
  let userId = localStorage.getItem('userId');

  useEffect(() => {
    let authToken = localStorage.getItem('Auth Token');

    if (!authToken){
      navigate('/');
    }
  }, [])

  const clearErrors = () => {
    setNameError(null);
    setNumExercisesError(null);
  }

  const isInputInvalid = () => {
    let invalidName = !worksheetName;
    let invalidNumExercise = !isPositiveInteger(numberOfExercises);

    setNameError(invalidName ? t("specifyName.label") : null);
    setNumExercisesError(invalidNumExercise ? t("specifyWholeNumber.label") : null);

    return invalidName || invalidNumExercise;
  }

  const getExercises = async () => {
    clearErrors();
    if(isInputInvalid()){
      return;
    }
    // generate exercises
    let exercises = await getRandomExercises(numberOfExercises, difficulty);
    exercises = exercises.map(exercise => { return {exercise, completed: false}});
    // create worksheet in DB and save the exercise set
    let result = await createNewWorksheet(userId, worksheetName, exercises);
    let worksheetId = result.worksheetId;
    // navigate to practice page
    let params = {
        exercises: exercises, 
        worksheetId: worksheetId
    };
    navigate('/practice', {state: params});
  }

  return (
    <Container style={{marginTop: 50}}>
      <h2 style={{marginBottom: 40}}>
        {t("newWorksheet.label")}
      </h2>
      <FormControl>
        <TextField 
          error={nameError ? true : false}
          helperText={nameError}
          label={t("worksheetName.label")} 
          variant="outlined" 
          placeholder={t("enterWorksheetName.label")} 
          onChange={(e) => setWorksheetName(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <br></br>
        <TextField 
          error={numExercisesError ? true : false}
          helperText={numExercisesError}
          label={t("numberOfExercises.label")} 
          variant="outlined" 
          placeholder={t("enterNumberOfExercises.label")} 
          onChange={(e) => setNumberOfExercises(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          style={{marginBottom:15}}
        />
        <FormLabel>
          {t("difficulty.label")}
        </FormLabel>
        <RadioGroup 
          row
          value={difficulty}
          onChange={(event) => setDifficulty(event.target.value)}
        >
          <FormControlLabel
            value="easy"
            control={<Radio />} 
            label={t("easy.label")}
          />
          <FormControlLabel 
            value="medium" 
            control={<Radio />} 
            label={t("medium.label")}
          />
          <FormControlLabel 
            value="hard" 
            control={<Radio />} 
            label={t("hard.label")} 
          />
          <FormControlLabel 
            value="harder" 
            control={<Radio />} 
            label={t("harder.label")} 
          />
          <FormControlLabel 
            value="any" 
            control={<Radio />} 
            label={t("surpriseMe.label")} 
          />
        </RadioGroup>
        <Button variant="contained" onClick={getExercises}>
          {t("start.label")}
        </Button>
      </FormControl>
    </Container>
  )
}