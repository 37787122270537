import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import spanish from "./assets/translations/es.json";
import english from "./assets/translations/en.json";

const resources = {
  es: {
    translation: spanish
  },
  en: {
    translation: english
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: localStorage.getItem("language") ?? 'en',
    debug: true,
    returnObjects: true,
    interpolation: {
      escapeValue: false,
    }
  });

export default i18n;