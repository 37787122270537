import React, { useState }  from "react";
import { Link, useNavigate } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import LanguageSelector from "./LanguageSelector";
import { useTranslation } from "react-i18next";

const Header = () => {

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const logout = () => {
    // destroy the token from the session
    localStorage.removeItem('Auth Token');
    navigate('/');
  }

  return (
    <AppBar position="static" sx={{ bgcolor: "#e74e35" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
          >
            Skilledmatica
          </Typography>
          {/* Header for small screen */}
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
                <MenuItem 
                  component={Link}
                  to="/home"
                >
                  <Typography textAlign="center">
                    {t("home.label")}
                  </Typography>
                </MenuItem>
                <MenuItem 
                  component={Link}
                  to="/about"
                >
                  <Typography textAlign="center">
                    {t("about.label")}
                  </Typography>
                </MenuItem>
                <MenuItem 
                  component={Link}
                  to="/pricing"
                >
                  <Typography textAlign="center">
                    {t("pricing.label")}
                  </Typography>
                </MenuItem>
                <MenuItem>
                  <LanguageSelector />
                </MenuItem>
            </Menu>
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
          >
            Skilledmatica
          </Typography>

          {/* Header for big screen */}
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              <Button
                component={Link}
                to="/home" 
                style={{textDecoration: "none", color: "white"}}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {t("home.label")}
              </Button>
              <Button
                component={Link}
                to="/about" 
                style={{textDecoration: "none", color: "white"}}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {t("about.label")}
              </Button>
              <Button
                component={Link}
                to="/pricing" 
                style={{textDecoration: "none", color: "white"}}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {t("pricing.label")}
              </Button>
              <Button >
                <LanguageSelector isWhite={true}/>
              </Button>
          </Box>
          { localStorage.getItem('Auth Token') && 
            <Button color="inherit" onClick={logout}>
              Logout
            </Button>
          }
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Header;