import React, {useState, useEffect} from "react";
import * as math from 'mathjs';
import { GrPowerReset } from "react-icons/gr";
import { BiUndo } from "react-icons/bi";
import ShowEquation from "../Components/ShowEquation";
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AntSwitch from "./AntSwitch";
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Fab from '@mui/material/Fab';
import Button from '@mui/material/Button';
import { 
  getComparisonSign, 
  replaceSqrtWithExponent,
  replaceExponentWithSqrt 
} from "../utils";
import { useTranslation } from "react-i18next";

export default function Keypad({
  originalEquation, 
  equation, 
  setEquation, 
  steps, 
  setSteps, 
  setSolved, 
  comparisonSign, 
  setComparisonSign, 
  setMostlyFormattedEquation,
  markExerciseAsSolved,
  setX
}) {

  const { t, i18n } = useTranslation();
  const [digitsDisabled, setDigitsDisabled] = useState(true);
  const [operationsDisabled, setOperationsDisabled] = useState(false);
  const [undoDisabled, setUndoDisabled] = useState(true);
  const [showNegative, setShowNegative] = useState(false);
  const [disabledDigitClicked, setDisabledDigitClicked] = useState(false);
  const [disabledOperatorClicked, setDisabledOperatorClicked] = useState(false);

  const hideAlerts = () =>{
    setDisabledDigitClicked(false);
    setDisabledOperatorClicked(false);
  }

  useEffect(() => {
    // "reset" values when OG equation changes
    hideAlerts();
    setUndoDisabled(true);
    setDigitsDisabled(true);
    setOperationsDisabled(false);
  }, [originalEquation])

  const createNewEquation = (leftside, rightside, disableOperations, disableUndo, newComparisonSign) => {
    hideAlerts();
    let newEquation;
    if(newComparisonSign){
      newEquation = `${leftside}${newComparisonSign}${rightside}`;
    }
    else{
      newEquation = `${leftside}${comparisonSign}${rightside}`;
    }
    setEquation(newEquation);
    setOperationsDisabled(disableOperations);
    setDigitsDisabled(isNaN(leftside[leftside.length - 1]) ? !disableOperations : digitsDisabled);
    setUndoDisabled(disableUndo);
    if(!disableOperations){
      setMostlyFormattedEquation(null);
    }
    return newEquation;
  }

  const splitEquation = (eq) => {
    let split_equation = eq.split(comparisonSign);
    return [split_equation[0], split_equation[1]];
  }

  const appendDigit = (digit) => {
    let [leftside, rightside] = splitEquation(equation);
    let newComparisonSign = comparisonSign;
    let digitToAppend = digit.toString();
    if(showNegative){
      digitToAppend = `(-${digitToAppend})`;
      // if multiplying or dividing...  
      if(["*","/"].includes(leftside[leftside.length - 1])){
        // flip inequality
        newComparisonSign = flipComparisonSign();
      }
    }

    leftside = leftside + digitToAppend;
    rightside = rightside + digitToAppend;

    createNewEquation(leftside, rightside, false, false, newComparisonSign);
    setComparisonSign(newComparisonSign);
  }

  const add = () => {
    let [leftside, rightside] = splitEquation(equation);

    let newDisplay = (
      <>
        <ShowEquation equation={leftside} onlyOneSide={true}/> +
        {comparisonSign}
        <ShowEquation equation={rightside} onlyOneSide={true}/> +
      </>
    );
    setMostlyFormattedEquation(newDisplay);

    leftside = leftside + '+';
    rightside = rightside + '+';

    createNewEquation(leftside, rightside, true, false);
  }

  const substract = () => {
    let [leftside, rightside] = splitEquation(equation);

    let newDisplay = (
      <>
        <ShowEquation equation={leftside} onlyOneSide={true}/> -
        {comparisonSign}
        <ShowEquation equation={rightside} onlyOneSide={true}/> -
      </>
    );
    setMostlyFormattedEquation(newDisplay);

    leftside = leftside + '-';
    rightside = rightside + '-';

    createNewEquation(leftside, rightside, true, false);
  }

  const multiply = () => {
    let [leftside, rightside] = splitEquation(equation);

    let newDisplay = (
      <>
        <ShowEquation equation={'('+leftside+')'} onlyOneSide={true}/>*
          {comparisonSign}
        <ShowEquation equation={'('+rightside+')'} onlyOneSide={true}/>*
      </>
    );
    setMostlyFormattedEquation(newDisplay)

    leftside = '(' + leftside + ')*';
    rightside = '(' + rightside + ')*';

    createNewEquation(leftside, rightside, true, false);
  }

  const flipComparisonSign = () => {
    // if inequality, flip comparison sign
    if(comparisonSign === ">"){
      return "<";
    }
    else if(comparisonSign === "<"){
      return ">";
    }
    else{
      return comparisonSign;
    }
  }

  const negate = () => {
    let [leftside, rightside] = splitEquation(equation);

    leftside = '-(' + leftside + ')';
    rightside = '-(' + rightside + ')';

    // if inequality, comparison sign will change
    let newComparisonSign = flipComparisonSign();

    createNewEquation(leftside, rightside, false, false, newComparisonSign);

    setComparisonSign(newComparisonSign);
  }

  const squareRoot = () => {
    let [leftside, rightside] = splitEquation(equation);

    leftside = 'sqrt(' + leftside.trim() + ')';
    rightside = 'sqrt(' + rightside.trim() + ')';

    createNewEquation(leftside, rightside, false, false);
  }

  const exponent = () => {
    let [leftside, rightside] = splitEquation(equation);

    leftside = '(' + leftside.trim() + ')^2';
    rightside = '(' + rightside.trim() + ')^2';

    createNewEquation(leftside, rightside, false, false);
  }

  const divide = () => {
    let [leftside, rightside] = splitEquation(equation);

    let newDisplay = (
      <>
        <ShowEquation equation={'('+leftside+')'} onlyOneSide={true}/>/
          {comparisonSign}
        <ShowEquation equation={'('+rightside+')'} onlyOneSide={true}/>/
      </>
    );
    setMostlyFormattedEquation(newDisplay);

    leftside = '(' + leftside + ')/';
    rightside = '(' + rightside + ')/';

    createNewEquation(leftside, rightside, true, false);
  }

  const simplify = () => {
    let newSteps = [...steps, equation];
    let newEq = replaceSqrtWithExponent(equation);

    let [leftside, rightside] = splitEquation(newEq);

    if(leftside){
      leftside = String(math.simplify(leftside));
    }

    if(rightside){
      rightside = String(math.simplify(rightside));
    }

    // replace ()^1/2 with sqrt()
    leftside = replaceExponentWithSqrt(leftside);
    rightside = replaceExponentWithSqrt(rightside);
    
    let newEquation = createNewEquation(leftside, rightside, false, true);
    newSteps = [...newSteps, newEquation];
    setSteps(newSteps);

    if((leftside === 'x' && !rightside.includes('x')) 
        || (rightside === 'x' && !leftside.includes('x'))){
      setSolved(true);
      markExerciseAsSolved(newSteps);
      if(leftside === 'x'){
        setX(rightside);
      }
      else{
        setX(leftside);
      }
    }
    else{
      setSolved(false);
    }
  }

  const reset = () => {
    hideAlerts();
    setEquation(originalEquation);
    setUndoDisabled(true);
    setDigitsDisabled(true);
    setOperationsDisabled(false);
    setSteps([originalEquation]);
    setSolved(false);
    setMostlyFormattedEquation(null);
    // reset comparison sign, in case it was inverted
    setComparisonSign(getComparisonSign(originalEquation));
  }

  const undo = () => {
    hideAlerts();
    setEquation(steps[steps.length-1]); // last step
    setUndoDisabled(true);
    setDigitsDisabled(true);
    setOperationsDisabled(false);
    setMostlyFormattedEquation(null);
    // reset comparison sign, in case it was inverted
    setComparisonSign(getComparisonSign(steps[steps.length-1]));
  }

  const DigitButton = ({digit, className, isVariable=false}) => {  
    return ( 
      <Fab
        color={isVariable ? "success" : "primary"}
        onClick={() => digitsDisabled ? setDisabledDigitClicked(true) : appendDigit(digit)}   
        style={{...styles.button, color: "white"}}
        className={`${className} ${digitsDisabled ? "looksDisabled" : null}`}
      >
        {showNegative && !isVariable ? -digit : digit}
      </Fab>
    );
  }  

  const OperationButton = ({operator, operation, className}) => {  
    return (
      <Fab 
        color="warning"
        onClick={() => operationsDisabled ? setDisabledOperatorClicked(true) : operation()}  
        style={{...styles.button, color:"black"}}
        className={`${className} ${operationsDisabled ? "looksDisabled" : null}`}
      >
          {operator}
      </Fab>
    );
  }

  return (
    <>
    <div style={styles.calculator} className="calculator">
    <Card sx={{ minWidth: 275, bgcolor: "#EEEEEE" }}>
      <CardContent>
        <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
          <Typography variant="h4">-</Typography>
          <AntSwitch 
            inputProps={{ 'aria-label': 'ant design' }} 
            checked={!showNegative} 
            onChange={() => {setShowNegative(!showNegative);}}
          />
          <Typography variant="h4">+</Typography>
        </Stack>
        <div style={styles.center}>
          <DigitButton digit={7}/>
          <DigitButton digit={8}/>
          <DigitButton digit={9}/>
          <OperationButton operator={'+'} operation={add} className="add"/>
          <br/>
          <DigitButton digit={4}/>
          <DigitButton digit={5}/>
          <DigitButton digit={6}/>
          <OperationButton operator={'-'} operation={substract} className="substract"/>
          <br/>
          <DigitButton digit={1}/>
          <DigitButton digit={2} className="numberTwo"/>
          <DigitButton digit={3} className="numberThree"/>
          <OperationButton operator={'*'} operation={multiply} className="multiply"/>
          <br/>
          <DigitButton digit={0}/>
          <DigitButton digit={'x'} isVariable={true}/>
          <OperationButton operator={'(-)'} operation={negate} className="negate"/>
          <OperationButton operator={'/'} operation={divide} className="divide"/>
          <br/>
          <OperationButton operator={'√'} operation={squareRoot} className="squareRoot"/>
          <OperationButton operator={<>x<sup>2</sup></>} operation={exponent} className="exponent"/>
        </div>
        <Fab color="secondary" onClick={undo} className="undo" disabled={undoDisabled} style={{...styles.button}}><BiUndo/></Fab>{' '}
        <Fab color="error" onClick={reset} className="reset" style={styles.button}><GrPowerReset/></Fab>{' '}
        <Button 
          color="warning" 
          variant="contained" 
          onClick={() => operationsDisabled ? setDisabledOperatorClicked(true) : simplify()} 
          className={`simplify ${operationsDisabled ? "looksDisabled" : null}`} 
          style={styles.button}
        >
          {t("simplify.label")}
        </Button>
      </CardContent>
    </Card>
    </div>
    <Snackbar 
      open={disabledDigitClicked} 
      autoHideDuration={6000} 
      onClose={() => {setDisabledDigitClicked(false)}}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert severity="error" onClose={() => {setDisabledDigitClicked(false)}}>
        {t("cantInsertDigit.label")}
      </Alert>
    </Snackbar>
    <Snackbar 
      open={disabledOperatorClicked} 
      autoHideDuration={6000} 
      onClose={() => {setDisabledOperatorClicked(false)}}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert severity="error" onClose={() => {setDisabledOperatorClicked(false)}}>
        {t("cantInsertOperator.label")}
      </Alert>
    </Snackbar>
    </>
  );
}

const styles = {
  button: {
    fontSize: 30
  },
  round: {
    borderRadius: "50%",
    width: 60
  },
  calculator: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "fit-content",
    padding: 15,
    borderRadius: "10%"
  },
  center: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "fit-content"
  }
};