import React, {useState} from "react";
import Nav from 'react-bootstrap/Nav';
import { ImCheckmark } from "react-icons/im";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function ExerciseNav({exercises, currentExercise, setValues}) {
  
  const { t, i18n } = useTranslation();
  const [show, setShow] = useState(false);
  
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setShow(open);
  };

  return( 
    <>
    <Button
      className="showExercises" 
      onClick={toggleDrawer(true)} 
      style={{whiteSpace: "nowrap"}}
    >
      &#9776; {show ? t("hide.label") : t("show.label")} {t("exercises.label")}
    </Button>
    <Drawer
      open={show}
      onClose={toggleDrawer(false)}
    >
      <Box
        sx={{ width: 300 }}
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <h3 style={{padding: 20}}>
          {t("exercises.label")}
        </h3>
        <Nav
          style={{fontSize: 20}}
          activeKey={currentExercise}
          onSelect={
            (selectedKey) => {
              setValues(parseInt(selectedKey)); 
              toggleDrawer(false);
            }
          }
        >
        <List>
          {
            exercises.map((exercise,index) =>
            <ListItem button={true} key={exercise.exercise+index}>
              <Nav.Link eventKey={index} style={index===currentExercise ? styles.selected : null}>
                {index+1}.) {exercise.exercise}
                { exercise.completed ? <ImCheckmark/> : null }
              </Nav.Link>
            </ListItem>
            )
          }
        </List>
        </Nav>
      </Box>
    </Drawer>
    </>
  )
} 

const styles = {
  selected: {
    fontWeight: 700
  }
};