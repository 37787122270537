import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Container from '@mui/material/Container';
import { updateUser } from "../services/Users"

export default function Signup() {

  const [userType, setUserType] = useState("student");
  const [schoolName, setSchoolName] = useState();
  const [grade, setGrade] = useState("");
  const [schoolNameError, setSchoolNameError] = useState();
  const [gradeError, setGradeError] = useState();
  const navigate = useNavigate();
  let userId = localStorage.getItem('userId');

  useEffect(() => {
    let authToken = localStorage.getItem('Auth Token');

    if (!authToken){
      navigate('/');
    }
  }, [])

  const clearErrors = () => {
    setSchoolNameError(null);
    setGradeError(null);
  }

  const isInputInvalid = () => {
    // this dosn't do anything...
    return false;
  }

  const submit = async () => {
    clearErrors();
    if(isInputInvalid()){
      return;
    }
    // update user
    await updateUser(userId, userType, schoolName, grade);
    localStorage.setItem('userType', userType);
    navigate('/home');
  }

  return (
    <Container style={{marginTop: 50}}>
      <h2 style={{marginBottom: 40}}>
        Signup
      </h2>
      <FormControl fullWidth>
        <FormLabel>
          User Type
        </FormLabel>
        <RadioGroup 
          row
          value={userType}
          onChange={(event) => setUserType(event.target.value)}
        >
          <FormControlLabel
            value="student"
            control={<Radio />} 
            label="Student" 
          />
          <FormControlLabel 
            value="teacher" 
            control={<Radio />} 
            label="Teacher"
          />
        </RadioGroup>
        { userType ?
            userType === "student" ?
            <FormControl variant="outlined">
              <InputLabel id="demo-simple-select-label">Grade</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                value={grade}
                label="Grade"
                onChange={(event) => setGrade(event.target.value)}
              >
                <MenuItem value={"preschool"}>Preschool</MenuItem>
                <MenuItem value={"kindergarten"}>Kindergarten</MenuItem>
                <MenuItem value={"Grade1"}>Grade 1</MenuItem>
                <MenuItem value={"Grade2"}>Grade 2</MenuItem>
                <MenuItem value={"Grade3"}>Grade 3</MenuItem>
                <MenuItem value={"Grade4"}>Grade 4</MenuItem>
                <MenuItem value={"Grade5"}>Grade 5</MenuItem>
                <MenuItem value={"Grade6"}>Grade 6</MenuItem>
                <MenuItem value={"Grade7"}>Grade 7</MenuItem>
                <MenuItem value={"Grade8"}>Grade 8</MenuItem>
                <MenuItem value={"Grade9"}>Grade 9</MenuItem>
                <MenuItem value={"Grade10"}>Grade 10</MenuItem>
                <MenuItem value={"Grade11"}>Grade 11</MenuItem>
                <MenuItem value={"Grade12"}>Grade 12</MenuItem>
              </Select>
            </FormControl>
            :
            <TextField 
                error={schoolNameError ? true : false}
                helperText={schoolNameError}
                label="School" 
                variant="outlined" 
                placeholder="Enter your school" 
                onChange={(e) => setSchoolName(e.target.value)}
                InputLabelProps={{
                    shrink: true,
                }}
            />
          :
          null
        }
        <br></br>
        <Button variant="contained" onClick={submit}>
          Submit
        </Button>
      </FormControl>
    </Container>
  )
}