export async function getAssignments(userId) {
  const response = await fetch(`${process.env.REACT_APP_API}/users/${userId}/assignments`);
  return await response.json();
}

export async function createAssignment(userId, assignmentName, classroomIds, exercises) {
  const response = await fetch(`${process.env.REACT_APP_API}/users/${userId}/assignments`, {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({assignmentName, classroomIds, exercises})
  })
  return await response.json();
}