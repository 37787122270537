import React, {useState, useEffect} from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import { createAssignment } from "../services/Assignments";
import MultiSelect from "../Components/MultiSelect";
import ShowEquation from "../Components/ShowEquation";
import { isPositiveInteger } from "../utils";
import { useTranslation } from "react-i18next";
import { getSplitRandomExercises } from "../services/ExercisesService";

export default function NewAssignment() {

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { classrooms } = location?.state; 
  const [assignmentName, setAssignmentName] = useState();
  const [assignmentNameError, setAssignmentNameError] = useState();
  const [selectedClassroomIds, setSelectedClassroomIds] = useState([]);
  const [selectedClassroomsError, setSelectedClassroomsError] = useState();
  const [numberOfExercises, setNumberOfExercises] = useState(5);
  const [numExercisesError, setNumExercisesError] = useState();
  const [exercises, setExercises] = useState([]);
  const [exercisesError, setExercisesError] = useState([]);

  useEffect(() => {
    let arr = [...exercises];
    if(numberOfExercises > exercises.length){
      for (let i=exercises.length; i < numberOfExercises; i++) {
        arr.push({
          leftside: "", 
          rightside: "", 
          sign: "=",
          leftsideError: false,
          rightsideError: false
        });
      }
      setExercises(arr)
    }
    else if(numberOfExercises !== exercises.length){
      for (let i=exercises.length-1; i >= numberOfExercises; i--) {
        // avoid accidental loss of data
        if(exercises[i]?.leftside || exercises[i]?.rightside){
          break;
        }
        else{
          arr.pop();
        }
      }
      setExercises(arr)
    }
  }, [numberOfExercises])

  const editExercise = (index, key, value) => {
    setExercises(existingItems => {
      let newValue = {
        ...existingItems[index],
      }
      newValue[key] = value;
      return [
        ...existingItems.slice(0, index),
        newValue,
        ...existingItems.slice(index + 1),
      ]
    })
  }

  const generateExercises = async () => {
    let exercises = await getSplitRandomExercises(numberOfExercises, "any");
    setExercises(exercises);
  }

  let userId = localStorage.getItem('userId');

  const clearErrors = () => {
    setAssignmentNameError(null);
    setSelectedClassroomsError(null);
    setNumExercisesError(null);
  }

  const isInputInvalid = () => {
    let invalidName = !assignmentName;
    let invalidNumExercise = !isPositiveInteger(numberOfExercises);
    let invalidClassrooms = selectedClassroomIds.length <= 0;
    let invalidExercises = exercises.some(exercise => exercise.leftsideError === true || exercise.rightsideError === true);

    setAssignmentNameError(invalidName ? t("specifyName.label") : null);
    setNumExercisesError(invalidNumExercise ? t("specifyWholeNumber.label") : null);
    setSelectedClassroomsError(invalidClassrooms ? t("selectAtLeast1Classroom") : null);
    setExercisesError(invalidExercises ? t("fixExercises") : null);

    return invalidName || invalidNumExercise || invalidClassrooms || invalidExercises;
  }

  const submit = async () => {
    clearErrors(); 
    if(isInputInvalid()){
      return;
    }

    let finalExercises = exercises.map(value => 
      `${value.leftside}${value.sign}${value.rightside}`
    );

    // create assignment
    let result = await createAssignment(userId, assignmentName, selectedClassroomIds, finalExercises);
    console.log("result: ", result)

    // navigate to home page
    let params = {
      tabIndex: 1, // Assignments tab
      createdAssignment: assignmentName,
    };
    navigate('/home', {state: params});
  }

  return (
    <Container style={{marginTop: 50}}>
      <h2 style={{marginBottom: 40}}>
        {t("createAssignment")}
      </h2>
      <FormControl>
        <TextField 
          error={assignmentNameError ? true : false}
          helperText={assignmentNameError}
          label="Name" 
          variant="outlined" 
          placeholder={t("enterAssignmentName")} 
          onChange={(e) => setAssignmentName(e.target.value)}
          InputLabelProps={{
              shrink: true,
          }}
        />
        <MultiSelect 
          label={t("selectClassrooms")}
          options={classrooms} 
          setSelectedValues={setSelectedClassroomIds}
          error={selectedClassroomsError}
        />
        <br></br>
        <Row>
          <Col xs={1} style={{width: 150, color: numExercisesError ? "#d32f2f" : "black"}}>
            <label>{t("numberOfExercises.label")}:</label>
          </Col>
          <Col xs={1} style={{width: 150}}>
            <TextField 
              error={numExercisesError ? true : false}
              helperText={numExercisesError}
              variant="outlined" 
              placeholder={"5"} 
              defaultValue={'5'}
              onChange={(e) => setNumberOfExercises(e.target.value)}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              type="number"
              style={{marginBottom:15, width: 100}}
            />
          </Col>
          <Col>
            <Button variant="contained" onClick={generateExercises}>
              Generate exercises
            </Button>
          </Col>
        </Row>
        <br></br>
        { exercisesError ? 
          <Row>
          <div style={{color: "#d32f2f", marginBottom: 15}}>
            {exercisesError}
          </div> 
          </Row>: null
        }
        {
          exercises.map((value, index) => 
          <div key={index}>
          <Row>
            <Col xs={3}>
              <TextField 
                value={value.leftside}
                error={value.leftsideError}
                label={t("leftside")}
                variant="outlined" 
                placeholder="x-3"
                onChange={(e) => editExercise(index, "leftside", e.target.value)}
                InputLabelProps={{
                    shrink: true,
                }}
              />
            </Col>
            <Col xs={2}>
              <TextField 
                select 
                label={t("sign")}
                onChange={e => editExercise(index, "sign", e.target.value)} 
                sx={{ 
                  minWidth: 80
                }}
                value={value.sign}
              >
                <MenuItem value={"="}> 
                  = 
                </MenuItem>
                <MenuItem value={">"}> 
                  {'>'} 
                </MenuItem>
                <MenuItem value={"<"}> 
                  {'<'} 
                </MenuItem>
              </TextField>
            </Col>
            <Col xs={3}>
              <TextField 
                value={value.rightside}
                error={value.rightsideError}
                label={t("rightside")} 
                variant="outlined" 
                placeholder="5-3x" 
                onChange={(e) => editExercise(index, "rightside", e.target.value)}
                InputLabelProps={{
                    shrink: true,
                }}
              />
            </Col>
            <Col xs={4}>
            { value.leftside && value.sign && value.rightside ? 
              <ShowEquation 
                equation={`${value.leftside}${value.sign}${value.rightside}`} 
                comparisonSign={value.sign}
                editExercise={editExercise}
                exerciseIndex={index}
              /> : null
            }
            </Col>
          </Row>
          <br></br>
          </div>
        )}
        <Button variant="contained" onClick={submit}>
          {t("create")}
        </Button>
      </FormControl>
    </Container>
  )
}