import React, { useState, useEffect }  from "react";
import Keypad from "../Components/Keypad"
import ExerciseNav from "../Components/ExerciseNav"
import Button from "@mui/material/Button";
import Fab from '@mui/material/Fab';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { FcApproval } from "react-icons/fc";
import { Link, useLocation, useNavigate } from "react-router-dom"; 
import ShowEquation from "../Components/ShowEquation";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { 
  saveExercise, 
  getWorksheet 
} from "../services/Worksheets"
import { useTour } from "@reactour/tour";
import {getLeftSide, getRightSide, getComparisonSign} from "../utils";
import * as math from 'mathjs';
import NumberLine from "../Components/NumberLine";
import DangerousIcon from '@mui/icons-material/Dangerous';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTranslation } from "react-i18next";

export default function Practice() {

  const { t, i18n } = useTranslation();
  const { setIsOpen } = useTour();
  const navigate = useNavigate();
  const location = useLocation();
  let worksheetId = location?.state?.worksheetId; 
  const [exercises, setExercises] = useState(location?.state?.exercises ?? ["-2x + 3 = 2", "x - 1 = 0", "4x/2 > 2"].map(exercise => { return {exercise, completed: false}})); 
  const [currentExercise, setCurrentExercise] = useState(0);
  const [equation, setEquation] = useState(exercises[currentExercise].exercise);
  const [steps, setSteps] = useState([exercises[currentExercise].exercise]);
  const [solved, setSolved] = useState(false);
  const [notesPerExercise, setNotesPerExercise] = useState([]);
  const [comparisonSign, setComparisonSign] = useState(getComparisonSign(equation));
  const [mostlyFormattedEquation, setMostlyFormattedEquation] = useState();
  const [completedWorksheet, setCompletedWorksheet] = useState();
  const [originalEquation, setOriginalEquation] = useState(exercises[currentExercise].exercise);
  const [x, setX] = useState();
  const [replacedX, setReplacedX] = useState(false);
  const [simplified, setSimplified] = useState(false);
  let userId = localStorage.getItem('userId');

  useEffect(() => {
    if(!solved){
      setOriginalEquation(exercises[currentExercise].exercise);
      setReplacedX(false);
      setSimplified(false);
    }
  }, [solved, exercises, currentExercise])

  const getWorksheetNotes = async() => {
    let worksheet;
    if(userId && worksheetId){
      worksheet = await getWorksheet(userId, worksheetId);
      worksheet = worksheet.result;
    }
    else{
      let stepsPerExercises = notesPerExercise.map(oneExercise => { return {steps: oneExercise}});
      worksheet = {
        exercises: exercises,
        stepsPerExercises: stepsPerExercises
      };
    }
    // navigate to notes page
    let params = {
      worksheet: worksheet, 
    };
    navigate('/notes', {state: params});
  }

  const setValues = (index) => {
    if(userId && worksheetId){
      saveExercise(userId, worksheetId, steps);
    }
    setNotesPerExercise([...notesPerExercise, steps]);
    setEquation(exercises[index].exercise);
    setOriginalEquation(exercises[index].exercise);
    setCurrentExercise(index);
    setSteps([exercises[index].exercise])
    setSolved(false);
    setComparisonSign(getComparisonSign(exercises[index].exercise));
    setMostlyFormattedEquation(null);
    setReplacedX(false);
    setSimplified(false);
  }

  // go to previous exercise
  const previous = () => {
    let index;
    if(currentExercise > 0){
      index = currentExercise - 1;
      setValues(index);
    }
  }

  // go to next exercise
  const next = () => {
    let index;
    if(currentExercise < exercises.length - 1){
      index = currentExercise + 1;
      setValues(index);
    }
  }

  const markExerciseAsSolved = (newSteps) => {
    let newExercises = [...exercises];
    newExercises[currentExercise] = {
      exercise: exercises[currentExercise].exercise,
      completed: true
    };
    setExercises(newExercises);
    if(userId && worksheetId){
      saveExercise(userId, worksheetId, newSteps, newExercises);
    }

    let completedAll = newExercises.every((i) => {
      return i.completed === true
    });
    setCompletedWorksheet(completedAll);
    if(completedAll){
      setNotesPerExercise([...notesPerExercise, newSteps]);
    }
  }

  const simplify = (eq, setEq) => {
    let leftside = getLeftSide(eq);
    let rightside = getRightSide(eq);
    leftside = String(math.simplify(leftside));
    rightside = String(math.simplify(rightside));
    setEq(`${leftside}${getComparisonSign(eq)}${rightside}`);
    setSimplified(true);
  }

  return (
    <Container style={{marginTop: 20}}>
      <Row>
        <Col md={2}>
          <ExerciseNav 
            exercises={exercises} 
            currentExercise={currentExercise} 
            setValues={setValues}
          />
        </Col>
        <Col md={5}>
          <h2>
            {t("exercisePrompt.label")}
            { steps[0] === "-2x + 3 = 2" ?
              <Button 
                onClick={() => setIsOpen(true)} 
                disabled={equation !== "-2x + 3 = 2"} 
                color="success"
                variant="contained"
              >
                Start Tutorial
              </Button> : null
            }
          </h2>
          <div style={{...styles.equation, ...styles.center}} className="exercise">
            <span style={{display: 'inline-block', verticalAlign: 'middle', lineHeight: 'normal'}}>
            {mostlyFormattedEquation ? 
              mostlyFormattedEquation 
              : <ShowEquation equation={equation} comparisonSign={comparisonSign}/>
            }
            </span>
            {solved ? <FcApproval style={styles.check}/> : null}
          </div>
            {solved ? 
              <div className="checkAnswer" style={{fontSize: 25}}>
                <h4>{t("checkAnswer.label")}:</h4>
                <ShowEquation 
                  equation={originalEquation} 
                  comparisonSign={getComparisonSign(originalEquation)}
                />{" "}
                { comparisonSign === "=" ?
                  !replacedX ?
                    <Button 
                      variant="contained" 
                      onClick={() => {
                        setOriginalEquation(originalEquation.replaceAll("x", `(${x})`));
                        setReplacedX(true);
                      }}
                    >
                      {t("replaceX.label")}
                    </Button>
                    :
                    !simplified ?
                    <Button 
                      variant="contained" 
                      color="warning"
                      onClick={() => simplify(originalEquation, setOriginalEquation)}
                    >
                      {t("simplify.label")}
                    </Button>
                    :
                    <FcApproval/>
                  :
                  <>
                    { !simplified ?
                      <Button 
                        variant="contained" 
                        color="warning"
                        onClick={() => simplify(originalEquation, setOriginalEquation)}
                      >
                        {t("simplify.label")}
                      </Button>
                      : eval(originalEquation) ? 
                        <CheckCircleIcon color="success"/> 
                        : 
                        <DangerousIcon color="error"/>
                    }
                    <NumberLine 
                      originalEquation={exercises[currentExercise].exercise}
                      setOriginalEquation={setOriginalEquation}
                      setSimplified={setSimplified}
                      initialX={x}
                    />
                  </>
                }
              </div>
              : null
            }
          { completedWorksheet ? 
            <>
              <Row>
                <FcApproval style={{fontSize: 300}}/>
                <h1 style={{textAlign: "center"}}>
                  {t("worksheetComplete.label")}
                </h1>
              </Row>
              <div style={{textAlign: "center"}}>
                <Button variant="contained" onClick={getWorksheetNotes}>
                  {t("viewNotes.label")}
                </Button>
                <br></br><br></br>
                <Button
                  variant="contained"
                  component={Link}
                  to="/home"
                >
                  {t("returnHome.label")}
                </Button>
              </div>
            </>
            :
            <>
            <Keypad 
              originalEquation={exercises[currentExercise].exercise} 
              equation={equation} 
              setEquation={setEquation}
              steps={steps}
              setSteps={setSteps}
              setSolved={setSolved}
              comparisonSign={comparisonSign}
              setComparisonSign={setComparisonSign}
              setMostlyFormattedEquation={setMostlyFormattedEquation}
              markExerciseAsSolved={markExerciseAsSolved}
              setX={setX}
            />
            <div style={styles.center}>
              <Fab 
                className="previous" 
                color="primary"
                onClick={previous} 
                disabled={currentExercise <= 0}
              >
                <ArrowBackIosNewIcon/>
              </Fab>
              <Fab 
                className="next" 
                color="primary"
                onClick={next} 
                disabled={currentExercise >= exercises.length - 1}
              >
                <ArrowForwardIosIcon/>
              </Fab>
            </div>
          </>
          }
        </Col>
        <Col md={5} className="steps">
          <h3>{t("steps.label")}</h3>
          <ul>
          {
            steps.map((step,index) =>
              <li key={index+step}>{index+1}.) <ShowEquation equation={step} comparisonSign={getComparisonSign(step)}/></li>
            )
          }
          </ul>
        </Col>
      </Row>
    </Container>
  );
}

const styles = {
  center: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "fit-content"
  },
  container: {
    marginTop: 20,
  },
  equation: {
    fontSize: 50,
    minHeight: '200px',
    lineHeight: '200px',
    textAlign: 'center'
  },
  check: {
    marginBottom: 5
  }
};