import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

export default function ShowAssignments({assignments, setAssignments}) {
  return( 
    <>
    {
      assignments.length > 0 ? 
        <Row xs={1} md={2} lg={3} xl={4}>
        {assignments.map((assignment, index) =>
          <Col key={assignment.id} style={{marginBottom: 20}}>
          <Card style={{ width: '18rem' }}>
            <Card.Body>
              <Card.Title>
                {assignment.assignmentName}
              </Card.Title>
              <br></br>
              {' '}
            </Card.Body>
          </Card>
          </Col>
        )}
        </Row>
        : <div>No assignments yet! Create one.</div>
        }
    </>
  )
};