import React from "react";
import { useLocation } from 'react-router-dom';
import ShowEquation from "../Components/ShowEquation";
import { ImCheckmark } from "react-icons/im";
import {getLeftSide, getRightSide, getComparisonSign} from "../utils";
import Container from '@mui/material/Container';
import { useTranslation } from "react-i18next";

const isSolved = (eq) => {
  let leftside = getLeftSide(eq);
  let rightside = getRightSide(eq);

  if((leftside === 'x' && !rightside.includes('x')) 
      || (rightside === 'x' && !leftside.includes('x'))){
    return true; // problem solved!
  }
  else{
    return false;
  }
}

export default function Notes() {

  const { t, i18n } = useTranslation();
  const location = useLocation();
  const { worksheet } = location.state; 

  return (
    <Container style={{marginTop: 20}}>
    <h1>{t("notesTitle.label")}</h1>
    <br></br>
    <h3>{worksheet.worksheetName}</h3>
    <br></br>
    <ul>
      { worksheet?.stepsPerExercises.length > 0 ?
        worksheet?.stepsPerExercises.map((steps, exerciseIndex) => {
          return (
            <div key={exerciseIndex}>
              <h2>
                {exerciseIndex+1}.) 
                <ShowEquation 
                  equation={steps.steps[0]} 
                  comparisonSign={getComparisonSign(steps.steps[0])}
                />{' '}
                { isSolved(steps.steps[steps.steps.length-1]) ? <ImCheckmark/> : null }
              </h2>
              <ul>
                {steps.steps.map((step, index) =>
                  <li key={index+step}> <ShowEquation equation={step} comparisonSign={getComparisonSign(step)}/></li>
                )}
              </ul>
            </div>
          )
        })
        :
        <p>No notes yet. Notes will be added as you work on the worksheet.</p>
      }
    </ul>
    </Container>
  )
}