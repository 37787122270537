export async function getRandomExercises(count, difficulty) {
  try{
    const response = await fetch(`${process.env.REACT_APP_API}/randomExercises/${difficulty}/${count}`);
    return await response.json();
  }catch(error) {
    return [];
  }
}

export async function getSplitRandomExercises(count, difficulty) {
  try{
    const response = await fetch(`${process.env.REACT_APP_API}/splitRandomExercises/${difficulty}/${count}`);
    return await response.json();
  }catch(error) {
    return [];
  }
}