export async function createUser(userId, email) {
  const response = await fetch(`${process.env.REACT_APP_API}/users`, {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({userId, email})
  })
  return await response.json();
}

export async function updateUser(userId, userType, schoolName, grade) {
  const response = await fetch(`${process.env.REACT_APP_API}/users`, {
    method: 'PUT',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({userId, userType, schoolName, grade})
  })
  return await response.json();
}

export async function getUser(userId) {
  const response = await fetch(`${process.env.REACT_APP_API}/users/${userId}`);
  return await response.json();
}