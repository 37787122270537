export async function getWorksheets(userId) {
  const response = await fetch(`${process.env.REACT_APP_API}/users/${userId}/worksheets`);
  return await response.json();
}

export async function getWorksheet(userId, worksheetId) {
  const response = await fetch(`${process.env.REACT_APP_API}/users/${userId}/worksheets/${worksheetId}`);
  return await response.json();
}

export async function createNewWorksheet(userId, worksheetName, exercises) {
  const response = await fetch(`${process.env.REACT_APP_API}/users/${userId}/worksheets`, {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({worksheetName, exercises})
  })
  return await response.json();
}

export async function saveExercise(userId, worksheetId, steps, exercises) {
  const response = await fetch(`${process.env.REACT_APP_API}/users/${userId}/worksheets/${worksheetId}/saveExercise`, {
    method: 'PUT',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({steps, exercises})
  })
  return await response.json();
}

export async function deleteWorksheet(userId, worksheetId) {
  const response = await fetch(`${process.env.REACT_APP_API}/users/${userId}/worksheets/${worksheetId}`, {
    method: 'DELETE'
  });
  return await response.json();
}