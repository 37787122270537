import React from "react";
import Container from 'react-bootstrap/Container';
import { useTranslation } from "react-i18next";

export default function About() {

  const { t, i18n } = useTranslation();

  return (
    <Container style={{marginTop: 20}}>
      <h1>{t("about.label")}</h1>
      <h3>{t("about1.label")}</h3>
      <p>{t("about2.label")}</p>
      <p>{t("about3.label")}</p>
      <h3>{t("about4.label")}</h3>
      <p>{t("about5.label")}</p>
    </Container>
  );
}