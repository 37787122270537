export async function getClassrooms(userId) {
  const response = await fetch(`${process.env.REACT_APP_API}/users/${userId}/classrooms`);
  return await response.json();
}

export async function createClassroom(userId, classroomName) {
  const response = await fetch(`${process.env.REACT_APP_API}/users/${userId}/classrooms`, {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({classroomName})
  })
  return await response.json();
}