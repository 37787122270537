import React, { useEffect, useState }  from "react";
import { useNavigate, useLocation } from "react-router-dom";
import StudentHome from "../Components/StudentHome";
import TeacherHome from "../Components/TeacherHome";

export default function Home() {

  let [userType, setUserType] = useState(localStorage.getItem('userType')); 
  const navigate = useNavigate();
  const location = useLocation();
  const tabIndex = location?.state?.tabIndex; 
  const createdAssignment = location?.state?.createdAssignment;

  useEffect(() => {
    let authToken = localStorage.getItem('Auth Token');

    if (!authToken){
      navigate('/');
    }
  }, [navigate])

  return (
    <>
    {
      userType === "teacher" ?
      <TeacherHome tabIndex={tabIndex} createdAssignment={createdAssignment}/>
      :
      <StudentHome/>
    }
    </>
  );
}