const getComparisonSign = (eq) => {
  if(eq.includes("<")){
    return "<";
  }
  else if(eq.includes(">")){
    return ">";
  }
  else if(eq.includes("=")){
    return "=";
  }
}

const getLeftSide = (eq) => {
  let splitEquation = eq.split(getComparisonSign(eq));
  return splitEquation[0];
}

const getRightSide = (eq) => {
  let splitEquation = eq.split(getComparisonSign(eq));
  return splitEquation[1];
}

const replaceSqrtWithExponent = (equation) => {
  const searchTerm = "sqrt(";
  let finished = false;

  do {
    let sqrtStartIdx = equation.indexOf(searchTerm);
    if(sqrtStartIdx === -1){
      finished = true;
    }
    else{
      let sqrtEndIdx = searchTerm.length + sqrtStartIdx;
      let afterOpenParenthesis = equation.slice(sqrtEndIdx-1);
      let closingParenthesisIdx = afterOpenParenthesis.indexOf(")") + sqrtEndIdx;
      equation = (equation.slice(0,sqrtStartIdx) 
                + equation.slice(sqrtEndIdx-1,closingParenthesisIdx) 
                + "^(1/2)")
                + equation.slice(closingParenthesisIdx)
    }
  } while (!finished);

  return equation;
}

const replaceExponentWithSqrt = (equation) => {
  // remove spaces
  equation = equation.replace(/\s/gm, '');

  const searchTerm = "^(1/2)";
  let finished = false;

  do {
    let expStartIdx = equation.indexOf(searchTerm);
    if(expStartIdx === -1){
      finished = true;
    }
    else{
      let expEndIdx = searchTerm.length + expStartIdx;
      let beforeClosingParenthesis = equation.slice(0, expStartIdx);
      if(beforeClosingParenthesis[beforeClosingParenthesis.length-1] !== ")"){
        equation = (equation.slice(0,expStartIdx-1)
                + "sqrt(" 
                + equation[expStartIdx-1]
                + ")"
                + equation.slice(expEndIdx))
      }
      else{
        let openingParenthesisIdx = beforeClosingParenthesis.lastIndexOf("(");
        equation = (equation.slice(0,openingParenthesisIdx)
                + "sqrt" 
                + equation.slice(openingParenthesisIdx,expStartIdx) 
                + equation.slice(expEndIdx))
      }
    }
  } while (!finished);

  return equation;
}

export {
  getComparisonSign,
  getLeftSide,
  getRightSide,
  replaceSqrtWithExponent,
  replaceExponentWithSqrt
};