import React, { useEffect, useState }  from "react";
import { Link } from "react-router-dom"; 
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import LinearProgressWithLabel from "../Components/LinearProgressWithLabel"
import AddIcon from '@mui/icons-material/Add';
import { GoTrashcan } from "react-icons/go";
import { getWorksheets, deleteWorksheet } from "../services/Worksheets"
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Fab from '@mui/material/Fab';
import { useTranslation } from "react-i18next";

export default function StudentHome() {

  const { t, i18n } = useTranslation();
  const [worksheets, setWorksheets] = useState([]);
  let [userId, setUserId] = useState(localStorage.getItem('userId')); 
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedWorksheet, setSelectedWorksheet] = useState({});

  const handleDialogClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const getUserWorksheets = async () => {
      setLoading(true);
      try{
        let theWorksheets = await getWorksheets(userId);
        setWorksheets(theWorksheets.result);
      }
      catch{
        console.log("getWorksheets failed :(");
      }
      setLoading(false);
    }
    getUserWorksheets();
  }, [userId])

  const deleteAWorksheet = async (worksheetId, index) => {
    // destroy the token from the session
    try{
      await deleteWorksheet(userId, worksheetId);
      setWorksheets(worksheets.filter((value, i) => i !== index));
    }
    catch(error){
      console.log("error: ", error);
    }
    handleDialogClose();
  }
  
  const getPercentCompleted = (worksheet) => {
    let numCompleted = worksheet.exercises.filter(x => x.completed===true).length;
    let total = worksheet.exercises.length;
    let percentCompleted = (numCompleted/total)*100;
    return percentCompleted;
  }

  return (
    <>
    <Dialog
      open={open}
      onClose={handleDialogClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t("deleteWorksheetTitle.label")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t("deleteWorksheetBody.label")} "{selectedWorksheet?.name}"?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose}>No</Button>
        <Button onClick={() => deleteAWorksheet(selectedWorksheet?.id, selectedWorksheet?.index)} autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
    <Container style={{marginTop: 20}}>
      <h3>{t("worksheetTitle.label")} &nbsp;
        <Fab 
          component={Link} 
          to="/newworksheet"
          color="primary" 
          aria-label="add"
          style={{textDecoration: "none", color: "white"}}
        >
          <AddIcon/>
        </Fab>
      </h3>
      { loading ? "Loading ..." :
      worksheets.length > 0 ? 
        <Row xs={1} md={2} lg={3} xl={4}>
        {worksheets.map((worksheet, index) =>
          <Col key={worksheet.id} style={{marginBottom: 20}}>
          <Card style={{ width: '18rem' }}>
            <Card.Body>
              <Card.Title>
                {worksheet.worksheetName}
                <Tooltip title="Delete" placement="top">
                  <IconButton 
                    onClick={() => {
                      setSelectedWorksheet({
                        name: worksheet.worksheetName, 
                        id: worksheet.id, 
                        index: index
                      });
                      setOpen(true);
                    }}
                    className="float-end"
                  >
                    <GoTrashcan/>
                  </IconButton>
                </Tooltip>
              </Card.Title>
              <br></br>
              <LinearProgressWithLabel value={getPercentCompleted(worksheet)} />
              <br></br>
              <Button 
                variant="contained"
                component={Link}
                to="/practice" 
                state={{ 
                  exercises: worksheet.exercises, 
                  worksheetId: worksheet.id 
                }}
                style={{textDecoration: "none", color: "white"}}
              >
                { getPercentCompleted(worksheet) < 100 ?
                  t("continue.label") : t("redo.label") }
              </Button>
              {' '}
              <Button 
                variant="contained" 
                component={Link}
                to="/notes" 
                state={{ worksheet: worksheet }}
                style={{textDecoration: "none", color: "white"}}
              >
                {t("viewNotes.label")}
              </Button>
            </Card.Body>
          </Card>
          </Col>
        )}
        </Row>
        : <div>No worksheets yet! Create one.</div>
        }
    </Container>
    </>
  );
}