import React, {useState, useEffect}  from "react";
import { useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import ShowClassrooms from "./ShowClassrooms";
import ShowAssignments from "./ShowAssignments";
import CreateClassroom from "./CreateClassroom";
import { getClassrooms } from "../services/Classrooms";
import { getAssignments } from "../services/Assignments";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Alert from '@mui/material/Alert';
import { useTranslation } from "react-i18next";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default function TeacherHome({tabIndex, createdAssignment}) {

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [classrooms, setClassrooms] = useState([]);
  const [assignments, setAssignments] = useState([]);
  let userId = localStorage.getItem('userId');

  const getTeacherClassrooms = async () => {
    try{
      let theClassrooms = await getClassrooms(userId);
      setClassrooms(theClassrooms.result);
    }
    catch{
      console.log("getClassrooms failed :(");
    }
  }

  const getAllAssignments = async () => {
    try{
      let theAssignments = await getAssignments(userId);
      setAssignments(theAssignments.result);
    }
    catch{
      console.log("getClassrooms failed :(");
    }
  }

  const navigateNewAssignment = () => {
    // navigate to new assignment page
    let params = {
      classrooms: classrooms
    };

    navigate('/newassignment', {state: params});
  }

  useEffect(() => {
    getTeacherClassrooms();
    getAllAssignments();
  }, [userId])

  const [value, setValue] = useState(tabIndex ?? 0);
  const [asignmentName, setAsignmentName] = useState(createdAssignment);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="secondary"
        indicatorColor="secondary"
        aria-label="secondary tabs example"
      >
        <Tab value={0} label={t("classroomsTitle")} />
        <Tab value={1} label={t("assignmentsTitle")} />
      </Tabs>

      {/* Classrooms */}
      <TabPanel value={value} index={0}>
        <Container>
          <CreateClassroom 
            updateClassrooms={getTeacherClassrooms}
          />
          <ShowClassrooms
            classrooms={classrooms}
            setClassrooms={setClassrooms}
          />
        </Container>
      </TabPanel>
      
      {/* Assignments */}
      <TabPanel value={value} index={1}>
        <Container>
          <h3>{t("assignmentsTitle")} &nbsp;
            <Fab 
              onClick={navigateNewAssignment}
              color="primary" 
              aria-label="add"
            >
              <AddIcon/>
            </Fab>
          </h3>
          {
            asignmentName ?
            <>
            <Alert onClose={() => {setAsignmentName(null)}}>
              Assignment named "{asignmentName}" was created!
            </Alert>
            <br></br>
            </>
            : null
          }
          <ShowAssignments
            assignments={assignments}
            setAssignments={setAssignments}
          />
        </Container>
      </TabPanel>
    </Box>
  );
}