import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import GetStarted from "./Pages/GetStarted";
import Signup from "./Pages/Signup";
import About from "./Pages/About";
import Pricing from "./Pages/Pricing";
import NewWorksheet from "./Pages/NewWorksheet";
import Practice from "./Pages/Practice";
import NewAssignment from "./Pages/NewAssignment";
import Notes from "./Pages/Notes";
import { app } from './firebase-config'; // we need this to use firebase
import 'bootstrap/dist/css/bootstrap.min.css';

export default function BasicExample() {
  return (
    <Routes>
      <Route path="/" element={<GetStarted/>} />
      <Route path="/signup" element={<Signup/>} />
      <Route path="/home" element={<Home/>} />
      <Route path="/about" element={<About/>} />
      <Route path="/pricing" element={<Pricing/>} />
      <Route path="/newworksheet" element={<NewWorksheet/>} />
      <Route path="/newassignment" element={<NewAssignment/>} />
      <Route path="/practice" element={<Practice/>} />
      <Route path="/notes" element={<Notes/>} />
    </Routes>
  );
}