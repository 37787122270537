import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { useTranslation } from "react-i18next";

export default function NumberLine({min=-5, max=5, initialX, originalEquation, setOriginalEquation, setSimplified}) {

  useEffect(() => {
    setOriginalEquation(originalEquation.replaceAll("x", `(${initialX})`));
  }, [initialX, originalEquation, setOriginalEquation])

  const { t, i18n } = useTranslation();

  return (
    <Box sx={{ width: 300 }}>
      <div style={{fontSize: 20}}>
        {t("pickValueForX")}:
      </div>
      <Slider
        aria-label="Number"
        defaultValue={Math.ceil(eval(initialX))}
        valueLabelDisplay="auto"
        step={1}
        marks={Array.from({length: (max+1 - min)}, (v, k) => {return {value: k + min, label: k + min}})}
        min={min}
        max={max}
        track={false}
        onChangeCommitted={(_, x) => {
          setSimplified(false);
          setOriginalEquation(originalEquation.replaceAll("x", `(${x})`));
        }}
      />
    </Box>
  );
}
