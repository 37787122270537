import React,{ Suspense }  from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from "react-router-dom";
import Header from "./Components/Header";
import { TourProvider } from '@reactour/tour'
import steps from "./tourSteps"
import "./i18n";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Suspense fallback={null}>
        <Header />
        <TourProvider steps={steps}>
          <App />
        </TourProvider>
      </Suspense>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
