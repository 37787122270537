import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { createClassroom } from "../services/Classrooms";
import { useTranslation } from "react-i18next";

export default function CreateClassroom({updateClassrooms}) {

  const { t, i18n } = useTranslation();
  const [classroomName, setClassroomName] = useState();
  const [classroomNameError, setClassroomNameError] = useState();
  const [classroomCode, setClassroomCode] = useState();
  const navigate = useNavigate();
  let userId = localStorage.getItem('userId');

  useEffect(() => {
    let authToken = localStorage.getItem('Auth Token');

    if (!authToken){
      navigate('/');
    }
  }, [])

  const clearErrors = () => {
    setClassroomNameError(null);
  }

  const isInputInvalid = () => {
    let invalidName = !classroomName;

    setClassroomNameError(invalidName ? t("specifyName.label") : null);

    return invalidName;
  }

  const submit = async () => {
    clearErrors();
    if(isInputInvalid()){
      return;
    }
    // create classroom
    let result = await createClassroom(userId, classroomName);
    setClassroomCode(result.code);
    updateClassrooms();
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        '& > :not(style)': {
          m: 1,
          width: 280
        },
      }}
    >
      <Paper elevation={3} style={{padding:20}}>
        { !classroomCode ?
          <>
            <h4 style={{marginBottom: 40}}>
              {t("createClassroom")}
            </h4>
            <FormControl>
              <TextField 
                error={classroomNameError ? true : false}
                helperText={classroomNameError}
                label="Name" 
                variant="outlined" 
                placeholder={t("enterClassroomName")} 
                onChange={(e) => setClassroomName(e.target.value)}
                InputLabelProps={{
                    shrink: true,
                }}
              />
              <br></br>
              <Button variant="contained" onClick={submit}>
              {t("create")}
              </Button>
            </FormControl>
          </>
          :
          <>
            <h4 style={{marginBottom: 40}}>
              {t("classroomCreated")}
            </h4>
            <p>
              {t("classroomCreated.message")} {classroomCode}
            </p>
            <Button onClick={() => setClassroomCode()} variant="contained" >
              OK
            </Button>
          </>
        }
      </Paper>
    </Box>
  )
}